<template>
    <layout class="meeting">
        <!-- 面包屑 -->
        <div class="container infomation-mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>相关会议</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <!-- 会议列表 -->
        <div class="container meeting-box flex mt20">
            <div class="meeting-list mt10">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="meeting-item" :key="index" v-for="(item, index) in meetingList"
                        @click="meetJumpDetail(item.href, item.id)">
                        <across-media-item :item="item">
                            <template v-slot:desc>
                                <span class="meeting-item-address">
                                    <span class="color999 fs14 mr20" v-if="item.start_time"><i
                                        class="med med-31shijian"></i>{{ item.start_time.substr(0, 10) }}</span>
                                    <span class="color999 fs14" v-if="item.address"><i
                                            class="med med--didian"></i>{{ item.address }}</span>
                                </span>
                            </template>
                            <template v-slot:bottom>
                                <div class="flex x-left flex-wrap meeting-item-link">
                                    <a class="btn-link btn-link-0" @click.stop="handlePersonalClick(item.meeting_id)">个人中心</a>
                                </div>
                            </template>
                        </across-media-item>
                    </div>
                    <a-empty v-if="!meetingList.length && !loading" />
                </a-spin>
                <div class="t-c" v-if="meetTotal > 0">
                    <a-pagination :total="meetTotal" v-model:current="params.page" :page-size="params.pageSize"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import acrossMediaItem from '@/components/across-media-item';
import { jsonFormat } from '@/utils/jsonFormat';
import { committee_id,front_url } from '@/config';
export default {
    name: 'specialMaMeetingList',
    components: {
        layout,
        acrossMediaItem,
        layoutRight
    },
    data() {
        return {
            loading: true,
            // 会议列表请求参数
            params: {
                pageSize: 10,
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            },
            meetTotal: 0,
            // 会议列表
            meetingList: [],
            layoutRightConfig: {
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    type: 'ma',
                    title: '相关资讯', // 标题
                    moreLink:'/special-ma/infomation-list/'+this.$route.params.id,
                    detailLink:'/special-ma/infomation-detail/'+this.$route.params.id,
                    data: {
                        page: 1,
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id,
                        
                    },   // 参数条件
                },
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        page: 1,
                        PageSize: 4,
                        no_count: false,
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id
                    },   // 参数条件
                },
                guideDownload: {
                    show: true, // 是否显示
                    title: '相关指南', // 标题
                    type: 'ma',
                    methods: 'post',
                    moreLink: '/special-ma/guide-list/'+this.$route.params.id,
                    detailLink:'/special-ma/guide-detail/'+this.$route.params.id,
                    data: {
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        page: 1,
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
            },
        }
    },
    methods: {
        // 会议详情
        meetJumpDetail(href, id){
            let url = href ? href : front_url+'cn/web/index/' + id
            window.open(url, '_blank')
        },
        
        // 获取会议列表
        getMeetingList() {
            this.loading = true
            this.request.get('GetMeetingList', this.params).then(res => {
                let meetObj = {
                    title: 'cn_title',
                    img: 'pic_url'
                }
                this.meetTotal = Number(res.data.count)
                this.meetingList = jsonFormat(res.data.data, meetObj)
                this.loading = false
            })
        },
        // 跳转个人中心
        handlePersonalClick(id){
            let url = front_url + 'cn/reg/info/' + id
            window.open(url, '_blank')
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getMeetingList()
        },
        
    },
    created() {
        this.getMeetingList();
    },

    // 缓存页面二次进入的钩子函数
    activated() {
        // this.pageInit();
    },
}
</script>
<style lang="less" scoped>
.meeting {
    .filter-box:not(:last-child) {
        border-bottom: 0;
    }
}
.meeting-list {
    width: calc(66.66% - 30px);
    min-height: 600px;
    margin-right: 30px;

    .meeting-item {
        margin-bottom: 20px;
        border-bottom: 1px dashed #d8d8d8;

        &:last-child {
            border: 0;
        }
    }

    .btn-link {
        color: #999999;
        font-size: 12px;
        padding: 4px 20px;
        border: 1px solid #f9f9f9;
        // background: #f9f9f9;
        border-radius: 3px;
        margin-top: 5px;
        margin-right: 5px;
        flex-shrink: 0;

        &:hover {
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            color: #fff;
        }
    }
    .btn-link-0 {
        border-color: #3e7eaf;
        color: #3e7eaf;
        &:hover {
            background: #3e7eaf;
        }
    }
    .btn-link-1 {
        border-color: #8e35a9;
        color: #8e35a9;
        &:hover {
            background: #8e35a9;
        }
    }
    .btn-link-2 {
        border-color: #488c5f;
        color: #488c5f;
        &:hover {
            background: #488c5f;
        }
    }
    .btn-link-3 {
        border-color: #eea952;
        color: #eea952;
        &:hover {
            background: #eea952;
        }
    }
    .btn-link-4 {
        border-color: #5461c9;
        color: #5461c9;
        &:hover {
            background: #5461c9;
        }
    }
    .btn-link-5 {
        border-color: #709b1d;
        color: #709b1d;
        &:hover {
            background: #709b1d;
        }
    }
    .btn-link-6 {
        border-color: #a93553;
        color: #a93553;
        &:hover {
            background: #a93553;
        }
    }
    .btn-link-7 {
        border-color: #fc7647;
        color: #fc7647;
        &:hover {
            background: #fc7647;
        }
    }
    .btn-link-8 {
        border-color: #f7cf46;
        color: #f7cf46;
        &:hover {
            background: #f7cf46;
        }
    }
}

.meeting-right {
    width: 33.33%;
}

@media screen and (max-width:768px) {
    .infomation-mobile-bread {
        display: none;
    }
    .meeting-box {
        margin-top: 20px;
        display: block;
        padding: 0 10px;

        .meeting-list {
            width: 100%;
            min-height: 0;
            .btn-link{
                margin-top: 0;
            }
            .meeting-item {
                &-address {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                &-link{
                    overflow-x: scroll;
                    flex-wrap: nowrap;
                }
            }
        }

        .meeting-right {
            width: 100%;
            margin-top: 50px;
            img {
                width: 100%;
            }
        }
    }
}
</style>
